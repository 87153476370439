import Dialog from 'anchor-ui/dialog';
import Button from 'anchor-ui/button';

export const InactivityKickDialog = ({
  isOpen,
  setIsOpen,
  setCurrentChat,
}: {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  setCurrentChat: (chat: any) => void;
}) => {

  const handleClose = () => {
    setIsOpen(false);
  }

  const handleNavigateSubscription = () => {
    setCurrentChat({ id: '', name: '', icon: '', isGroup: false, showSubscriptionPage: true });
    handleClose();
  }

  return (
    <Dialog
      open={isOpen}
      header="Je bent uit de box gezet"
      hideDialog={handleClose}
      headerStyle={{ color: 'rgb(117, 117, 117)', fontSize: '24px', fontWeight: 'bolder', textAlign: 'center' }}
      style={{ backgroundColor: 'white' }}
      iconColor={'rgba(117, 117, 117, 0.5)'}
    >
      <section className='logout-dialog'>
        <p>
          Je bent verwijderd uit de chatbox wegens inactiviteit <br /><br />
          <b>Met een abonnement kun je zo lang blijven als je wilt, ook zonder iets te typen.</b>
        </p>
        <br />
        <div>
          <Button
            onClick={handleNavigateSubscription}
            style={{ width: 'auto' }}
            inverted
          >
            Bekijk abonnementen
          </Button>...
          <Button
            onClick={handleClose}
            style={{ width: 'auto' }}
          >
            Ik begrijp het
          </Button>
        </div>
      </section>
    </Dialog>
  );
}
