import { useEffect, useState } from 'react';
import List from 'anchor-ui/list';
import ListItem from 'anchor-ui/list-item';
import { IconClose } from 'anchor-ui/icons';
import { getActiveColor } from '../../utils/activeColor';
import Avatar from 'anchor-ui/avatar';
import { ActiveChannelInterface } from '../../interfaces/activeChannelInterface';
import { getFontSizeStyle } from '../../utils/getFontSizeStyle';
import { getAvatar } from '../../utils/getAvatar';
import { checkUserHasSubscription } from '../../utils/checkSubscription';
import { getProfileFromMetadata } from '../../utils/updateMetadata';

const ChannelListComponent = ({
  members,
  channels,
  activeChannels,
  onlineMembersCount,
  unreadCount,
  handleChangeChannel,
  currentChat,
  handleLeaveChannel,
  generalFontSize,
  loggedInUser,
}: {
  members: CometChat.GroupMember[];
  channels: CometChat.Group[];
  activeChannels: ActiveChannelInterface[];
  onlineMembersCount: { [key: string]: number } | null;
  unreadCount: { [id: string]: { nr: number, chat: ActiveChannelInterface } };
  handleChangeChannel: (channel: ActiveChannelInterface) => void;
  currentChat: ActiveChannelInterface;
  handleLeaveChannel: (channel: ActiveChannelInterface) => void;
  generalFontSize: string;
  loggedInUser: CometChat.User | null;
}) => {
  // Premium channel ids
  const premiumChannelIds = ["607080_jaren", "boswandeling", "serieus", "royalsonly", "vip"];

  const userProfile = getProfileFromMetadata(loggedInUser!);
  const favoriteChannelIds = userProfile?.favoriteChannels || [];

  const activeChannelsWithoutCurrent = activeChannels.filter(channel => channel.id !== currentChat.id);
  const onlineMembers = members.filter(member => member.getStatus() === 'online');

  const [currentChatIsPublic, setCurrentChatIsPublic] = useState(false);

  const LeaveChannelButton = ({ channel }: { channel: ActiveChannelInterface }) => {
    return (
      <button
        type="button"
        className='li-button text-white'
        onClick={(event) => {
          event.stopPropagation();
          handleLeaveChannel(channel);
        }}
      >
        <IconClose color={'white'} />
      </button>
    );
  };

  const getOnlineMembersCount = (channel: { guid: string, onlineMembersCount?: number }) => {
    let count = onlineMembersCount?.[channel.guid] ?? channel.onlineMembersCount ?? 0;
    if (count === 404) count = 1; // Fallback to 1 if count is 404 (not found)
    return `${count} online`;
  };

  useEffect(() => {
    const isActiveAndPublic = activeChannels.some(channel => channel.id === currentChat.id);
    setCurrentChatIsPublic(isActiveAndPublic);
  }, [currentChat, activeChannels]);

  // Combine and sort ALL channels, then filter during rendering
  const allChannels = [...channels]
    .sort((a, b) => {
      const aIsFavorite = favoriteChannelIds.includes(a.getGuid());
      const bIsFavorite = favoriteChannelIds.includes(b.getGuid());

      if (aIsFavorite && !bIsFavorite) {
        return -1; // a comes first
      }
      if (!aIsFavorite && bIsFavorite) {
        return 1; // b comes first
      }
      // Both are either favorites or not, sort by member count (descending)
      const countA = (a as any).onlineMembersCount ?? 0;
      const countB = (b as any).onlineMembersCount ?? 0;
      return countB - countA;
    });

  return (
    <div>
      <div className="active-channels">
        {currentChatIsPublic &&
          <List
            header="Huidig Kanaal"
            headerStyle={getFontSizeStyle(generalFontSize)}
          >
            <ListItem
              primaryText={currentChat.name}
              primaryTextStyle={getFontSizeStyle(generalFontSize)}
              secondaryText={
                onlineMembers.length ? onlineMembers.length + " online" :
                  getOnlineMembersCount({ guid: currentChat.id, onlineMembersCount: currentChat.onlineMembersCount })}
              avatar={
                <Avatar
                  image={getAvatar(currentChat.name, true)}
                  style={{ backgroundColor: getActiveColor() }}
                />
              }
              rightButton={<LeaveChannelButton channel={currentChat} />}
              style={{ backgroundColor: getActiveColor() }}
              activeChannel={true}
            />
          </List>
        }

        {activeChannelsWithoutCurrent.length > 0 &&
          <List
            header="Actieve Kanalen" // Updated to Premium
            headerStyle={getFontSizeStyle(generalFontSize)}
          >
            {activeChannelsWithoutCurrent.map((channel, index) => {
              const channelUnreadCount = unreadCount[channel.id] ? unreadCount[channel.id].nr : 0;

              return (
                <ListItem
                  key={index}
                  secondaryText={getOnlineMembersCount({ guid: channel.id, onlineMembersCount: (channel as any).onlineMembersCount })}
                  primaryText={channel.name}
                  primaryTextStyle={getFontSizeStyle(generalFontSize)}
                  badge={channelUnreadCount > 0 && <div className='badge-unread'>{channelUnreadCount}</div>}
                  avatar={
                    <Avatar
                      image={getAvatar(channel.name, true)}
                      style={{ backgroundColor: getActiveColor() }}
                    />
                  }
                  onClick={() => handleChangeChannel(channel)}
                  className={`channel-item active`}
                  rightButton={<LeaveChannelButton channel={channel} />}
                  style={{ backgroundColor: getActiveColor() }}
                  activeChannel={true}
                />
              );
            })}
          </List>
        }
      </div>

      {/* All non-active channels are now displayed as premium */}
      <List
        header="Premium Kanalen"
        headerStyle={getFontSizeStyle(generalFontSize)}
      >
        {allChannels
          .filter(channel => premiumChannelIds.includes(channel.getGuid()) && channel.getGuid() !== currentChat.id)
          .map((channel, index) => (
            <div key={index}>
              <ListItem
                primaryText={channel.getName()}
                primaryTextStyle={getFontSizeStyle(generalFontSize)}
                secondaryText={
                  checkUserHasSubscription(loggedInUser ? loggedInUser?.getRole() ?? "" : "") ?
                    getOnlineMembersCount({ guid: channel.getGuid(), onlineMembersCount: (channel as any).onlineMembersCount }) :
                    "??? online"
                }
                avatar={
                  <Avatar
                    image={getAvatar(channel.getName())}
                    style={{ backgroundColor: getActiveColor() }}
                  />
                }
                onClick={() => handleChangeChannel({
                  id: channel.getGuid(),
                  name: channel.getName(),
                  icon: channel.getIcon(),
                  onlineMembersCount: (channel as any).onlineMembersCount ?? 404,
                  joinedAt: 0,
                  isGroup: true
                })}
              />
            </div>
          ))}
      </List>

      {/* Free public channels  */}
      <List
        header="Gratis Kanalen"
        headerStyle={getFontSizeStyle(generalFontSize)}
      >
        {allChannels
          .filter(channel => !premiumChannelIds.includes(channel.getGuid()) && channel.getGuid() !== currentChat.id &&
            !activeChannels.some(activeChannel => activeChannel.id === channel.getGuid()))
          .map((channel, index) => {
            return (
              <div key={index}>
                <ListItem
                  primaryText={channel.getName()}
                  primaryTextStyle={getFontSizeStyle(generalFontSize)}
                  secondaryText={getOnlineMembersCount({ guid: channel.getGuid(), onlineMembersCount: (channel as any).onlineMembersCount })}
                  avatar={
                    <Avatar
                      image={getAvatar(channel.getName())}
                      style={{ backgroundColor: getActiveColor() }}
                    />
                  }
                  onClick={() => handleChangeChannel({
                    id: channel.getGuid(),
                    name: channel.getName(),
                    icon: channel.getIcon(),
                    onlineMembersCount: (channel as any).onlineMembersCount ?? 404,
                    joinedAt: 0,
                    isGroup: true
                  })}
                />
              </div>
            );
          })}
      </List>
    </div>
  );
};

export default ChannelListComponent;