import { useRef, useState, useEffect } from 'react';
import Button from 'anchor-ui/button';
import EmojiMenu from 'anchor-ui/emoji-menu';
import Commands from 'anchor-ui/commands';
import { IconEmoji, IconAddImage, IconSticker, IconSend } from 'anchor-ui/icons';
import defaultAvatar from "./../../assets/default_avatar.jpg";
import CreatePollModal from './CreatePollModal';
import { ActiveChannelInterface } from '../../interfaces/activeChannelInterface';
import GifPicker from 'gif-picker-react';
import { UserSettingsInterface } from '../../interfaces/userSettingsInterface';
import { getActiveColor } from '../../utils/activeColor';
import { ReplyMessageInterface } from '../../interfaces/replyMessageInterface';
import { CometChat } from "@cometchat/chat-sdk-javascript";

const MessageInputComponent = ({
  inputRef,
  handleSendMessage,
  handleSendImage,
  groupMembers,
  chatWithUser,
  emojiMenuOpen,
  setEmojiMenuOpen,
  setCommandMenuOpen,
  currentChat,

  messageToReply,
  setMessageToReply,

  startedTyping,
  setStartedTyping,

  usersTyping,
}: {
  inputRef: React.RefObject<HTMLInputElement>;
  handleSendMessage: (event: any, receiver: string, isGroup: boolean, messageText?: string) => void;
  handleSendImage: (event: React.ChangeEvent<HTMLInputElement>, receiver: string, isGroup: boolean, isForwarded?: boolean) => void;

  groupMembers?: CometChat.GroupMember[];
  chatWithUser?: CometChat.User;
  emojiMenuOpen: boolean;
  setEmojiMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setCommandMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  currentChat: ActiveChannelInterface;

  messageToReply: ReplyMessageInterface | null;
  setMessageToReply: React.Dispatch<React.SetStateAction<ReplyMessageInterface | null>>;

  startedTyping: boolean;
  setStartedTyping: (startedTyping: boolean) => void;

  usersTyping: { name: string; typingStarted: number; }[] | null;
}) => {
  const [pollModalOpen, setPollModalOpen] = useState(false);
  const [displayGifPicker, setDisplayGifPicker] = useState(false);
  const [receiver, setReceiver] = useState('');
  const fileInputRef = useRef<HTMLInputElement>(null);
  const gifPickerRef = useRef<HTMLDivElement>(null);
  const typingTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const mentionableUsers = groupMembers?.map((member) => ({
    value: member.getName(),
    prefix: '@',
    avatar: member.getAvatar() || defaultAvatar,
  }));

  if (chatWithUser) {
    mentionableUsers?.push({
      value: chatWithUser.getName(),
      prefix: '@',
      avatar: chatWithUser.getAvatar() || defaultAvatar,
    });
  };

  const commands = [
    ...mentionableUsers || [],
    {
      value: 'poll',
      prefix: '/',
    },
    {
      value: 'gif',
      prefix: '/',
    }
  ];

  // This function is used to stop the typing indicator when the user stops typing
  const handleStopTyping = () => {
    CometChat.endTyping(currentChat.isGroup ?
      new CometChat.TypingIndicator(currentChat.id, CometChat.RECEIVER_TYPE.GROUP)
      : new CometChat.TypingIndicator(chatWithUser?.getUid(), CometChat.RECEIVER_TYPE.USER));
    setStartedTyping(false);
  }

  // This function is used to send a typing indicator to the receiver
  // if the user starts typing a message and stops typing after 5 seconds of inactivity
  const handleStartTyping = () => {
    if (!startedTyping) CometChat.startTyping(currentChat.isGroup ?
      new CometChat.TypingIndicator(currentChat.id, CometChat.RECEIVER_TYPE.GROUP)
      : new CometChat.TypingIndicator(chatWithUser?.getUid(), CometChat.RECEIVER_TYPE.USER));
    setStartedTyping(true);

    // Clear the existing timeout before setting a new one
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    typingTimeoutRef.current = setTimeout(() => {
      handleStopTyping();
    }, 5000);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage(event, receiver, currentChat.isGroup);
      return;
    }

    // Start typing indicator if user types a message
    if (!startedTyping) handleStartTyping();
  }

  const handleImageClick = () => {
    if (!fileInputRef.current) return;
    fileInputRef.current.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;

    const file = event.target.files[0];
    if (file.type === 'image/gif') {
      // If the file is a GIF, return early
      return;
    }


    handleSendImage(event, currentChat.receiverId ?? currentChat.id, currentChat.isGroup);

    // Reset the input field
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const extractEmojis = (text: string) => {
    const emojiRegex = /\p{Emoji_Presentation}|\p{Extended_Pictographic}/gu;
    return text.match(emojiRegex);
  };

  const sendEmoji = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    if (!inputRef.current) return;
    const emoji = extractEmojis(event.currentTarget.innerHTML);

    if (!emoji) {
      console.error('Invalid emoji object');
      return;
    }

    inputRef.current.value = inputRef.current.value + emoji[0];
  };

  const handleMention = (command: string) => {
    if (!inputRef.current) return;

    const cursorPosition = inputRef.current.value.indexOf('@');
    const newValue = inputRef.current.value.slice(0, cursorPosition) + command;

    // Create a regular expression to match the username after the mention
    const regex = new RegExp(`@${command}\\s*${command}`, 'g');
    const finalValue = newValue.replace(regex, `@${command}`);

    // Set the new value
    inputRef.current.value = finalValue;
  };

  const handleCommand = (event: object, command: string) => {
    if (!inputRef.current) return;

    if (command.includes("@")) {
      handleMention(command);
    } else if (command === "/poll") {
      inputRef.current.value = "";
      setPollModalOpen(true);
    } else if (command === "/gif") {
      inputRef.current.value = "";
      setDisplayGifPicker(true);
    }
  };

  const handleSendGif = (gif: { url: string }) => {
    const event = { key: '' };
    handleSendMessage(event, receiver, currentChat.isGroup, gif.url);
    setDisplayGifPicker(false);
  }

  // Close GifPicker when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (gifPickerRef.current && !gifPickerRef.current.contains(event.target as Node)) {
        setDisplayGifPicker(false);
      }
    };

    if (displayGifPicker) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [displayGifPicker]);

  useEffect(() => {
    setReceiver(currentChat.receiverId ?? currentChat.id);
    setMessageToReply(null);
  }, [currentChat]);

  const getTypingActivity = () => {
    return <>
      {usersTyping && usersTyping.length > 0 && (
        <small className="typing-indicator">{usersTyping.length > 1 ? "Meerdere gebruikers zijn" : usersTyping[0].name + " is"} aan het typen<LoadingDots /></small>
      )}
    </>
  }

  return (
    <div className="message-input">
      <CreatePollModal
        open={pollModalOpen}
        setOpen={setPollModalOpen}
        currentChat={currentChat}
      />

      <EmojiMenu
        sendEmoji={sendEmoji}
        open={emojiMenuOpen}
        hideMenu={() => setEmojiMenuOpen(false)}
        style={{
          width: '100%',
          marginBottom: '5px',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      />

      {displayGifPicker && (
        <div ref={gifPickerRef} className="gif-picker">
          <GifPicker
            tenorApiKey={process.env.REACT_APP_TENOR_API_KEY + ''}
            country='NL'
            locale='nl_NL'
            onGifClick={(e) => handleSendGif(e)}
          />
        </div>
      )}

      <Commands
        header={inputRef.current?.value ? (inputRef.current.value.includes('@') ? "Mention" : "Commands") : ""}
        value={inputRef.current?.value || ""}
        commands={commands}
        onSelect={handleCommand}
        onMenuOpen={() => setCommandMenuOpen(true)}
        onMenuClose={() => setCommandMenuOpen(false)}

        onChange={() => { }}

        style={{
          width: '100%',
          zIndex: 1000,
          marginBottom: '5px',
          position: 'relative',
        }}
      />

      {messageToReply && (
        <div className="message-to-reply">
          <div className='reply-container'>
            <div className="message-to-reply-header">
              <b><small>{(messageToReply as any).sender.name}</small></b>
              <button
                onClick={() => setMessageToReply(null)}
                style={{ color: getActiveColor() }}
              >
                Sluiten
              </button>
            </div>
            <div className="message-to-reply-body">
              {messageToReply.message}
            </div>
          </div>
        </div>
      )}

      {getTypingActivity()}

      <section className="sendmessageinput-container">
        <Button
          iconButton
          onClick={() => setEmojiMenuOpen(!emojiMenuOpen)}
          className="sendmessageinput-button"
        >
          <IconEmoji />
        </Button>
        <input
          ref={inputRef}
          onChange={(e: { target: { value: string; }; }) => { inputRef.current!.value = e.target.value; }}
          placeholder="Typ iets..."
          onKeyDown={handleKeyDown}
          onBlur={() => handleStopTyping()}
          className="sendmessageinput-input"
          maxLength={500}
        />
        <div className="sendmessageinput-buttons">
          <Button
            iconButton
            onClick={() => setDisplayGifPicker(!displayGifPicker)}
            className="sendmessageinput-button"
          >
            <IconSticker />
          </Button>
          <Button
            iconButton
            onClick={handleImageClick}
            className="sendmessageinput-button"
          >
            <IconAddImage />
          </Button>
          <Button
            iconButton
            onClick={(e: any) => handleSendMessage(e, receiver, currentChat.isGroup, inputRef.current?.value)}
            className="sendmessageinput-button"
          >
            <IconSend color={getActiveColor()} />
          </Button>
        </div>
      </section>

      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
        accept="image/jpeg, image/png, image/bmp, image/webp, image/avif"
      />
    </div>
  );
};

export default MessageInputComponent;

const LoadingDots = () => {

  const [dots, setDots] = useState('.');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prev) => {
        if (prev.length === 3) return '.';
        return prev + '.';
      });
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return <span>{dots}</span>;
}