import Dialog from 'anchor-ui/dialog';
import Button from 'anchor-ui/button';

export const InactivityWarningDialog = ({
  inactivityDialogOpen,
  setInactivityDialogOpen,
  setCurrentChat,
  stopWarningCountdown,
  resetWarningCountdown,
}: {
  inactivityDialogOpen: boolean;
  setInactivityDialogOpen: (isOpen: boolean) => void;
  setCurrentChat: (chat: any) => void;
  stopWarningCountdown: () => void;
  resetWarningCountdown: () => void;
}) => {

  const handleNavigateSubscription = () => {
    setCurrentChat({ id: '', name: '', icon: '', isGroup: false, showSubscriptionPage: true });
    stopWarningCountdown();
    setInactivityDialogOpen(false);
  }

  const handleClose = () => {
    setInactivityDialogOpen(false);
    resetWarningCountdown();
  }

  return (
    <Dialog
      open={inactivityDialogOpen}
      header="👋 Ben je er nog?"
      hideDialog={() => setInactivityDialogOpen(false)}
      headerStyle={{ color: 'rgb(117, 117, 117)', fontSize: '24px', fontWeight: 'bolder', textAlign: 'center' }}
      style={{ backgroundColor: 'white' }}
      iconColor={'rgba(117, 117, 117, 0.5)'}
    >
      <section className='logout-dialog'>
        <p>
          Je hebt al een tijdje niks getypt. Als dat zo blijft, word je straks uit de box gezet. <br /><br />
          <b>Met een abonnement kun je zo lang blijven als je wilt, ook zonder iets te typen.</b>
        </p>
        <br />
        <div>
          <Button
            onClick={handleNavigateSubscription}
            style={{ width: 'auto' }}
            inverted
          >
            Bekijk abonnementen
          </Button>...
          <Button
            onClick={handleClose}
            style={{ width: 'auto' }}
          >
            Ik ben er nog!
          </Button>
        </div>
      </section>
    </Dialog>
  );
}
